<template>
    <div class="view">
        <img v-if="showBind && bindUser.head_portrait" class="logo" :src="rImg(bindUser.head_portrait)" :onerror="txImg" alt="">
        <img v-else class="logo" src="../../assets/logo.png" alt="">
        <div v-if="showBind">
            <p class="des">当前已有热浪账号: {{bindUser.name}}</p>
            <el-button class="btn" type="primary" round @click="auth(1)">关联热浪已有账号</el-button>
            <el-button class="btn" round @click.stop="setOtherPhone()">使用其他手机号</el-button>
        </div>
        <div v-else-if="showAuthPhone">
            <h3 class="title">绑定手机号</h3>
            <div v-if="showAuthPhone == 1">
                <el-button class="btn" type="primary" round @click="authPhone()">使用在吗手机号</el-button>
                <el-button class="btn" round @click.stop="showAuthPhone = 2">使用其他手机号</el-button>
            </div>
            <div v-else>
                <div class="input-box">
                    <i class="hwiconfont icon-shouji"></i>
                    <input type="tel" placeholder="请输入手机号" v-model="phone">
                </div>
                <div class="input-box">
                    <i class="hwiconfont icon-xiaoxi1"></i>
                    <input type="number" v-model="code" placeholder="请输入验证码">
                    <sCode :phone="phone" @getCode="getCode"></sCode>
                </div>
                <el-button class="btn" type="primary" round @click="bandPhone()">确定</el-button>
                <el-button class="btn" v-if="!bindUser.id" round @click="authPhone()">继续使用在吗手机号</el-button>
                <el-button class="btn" v-else round @click="auth(1)">直接关联热浪已有账号</el-button>
            </div>
        </div>
        <div v-else-if="authCode">
            <h3 class="title">授权</h3>
            <p class="des">确认使用在吗APP账号登录热浪，<br>我们将为您随机创建头像及昵称</p>
            <div>
                <el-button class="btn" type="primary" round @click="auth()">确认</el-button>
            </div>
        </div>
        <p class="des" v-else>登录中，请稍后...</p>
    </div>
</template>

<script>
    import sCode from "@components/common/code";
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {
            sCode,
        },
        data() {
            return {
                tzm: '',
                authCode: '', // 授权码
                phone: '',
                code: '',
                showAuthPhone: 0, // 1显示授权手机 2显示绑定手机
                bindUser: {},
                showBind: 0
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.tzm = this.$route.query.tzm;
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.login();
        },
        methods: {
            setOtherPhone() {
                this.showBind = 0;
                this.showAuthPhone = 2;
            },
            getCode(c) {
                this.$y_msg("请填写手机号", "error");
            },
            // ...mapMutations([])
            // 在吗登录
            async login() {
                let ret = await this.$y_ajax('api/login/oauth', {
                    token: this.tzm,
                    channel: 1
                })
                let _this = this;
                if (ret.code == 200) {
                    // 已授权
                    this.$y_setWebToken(ret.data.token);
                    let res = await this.$y_getUser();
                    if (!res.phone) {
                        this.showAuthPhone = 1;
                    } else {
                        setTimeout(() => {
                            _this._h5home();
                        }, 1500)
                    }
                } else if (ret.code == 201) {
                    // 未授权
                    this.authCode = ret.auth_code;
                    if (ret.can_bind_user) {
                        this.showBind = 1;
                        this.bindUser = ret.can_bind_user;
                    }
                } else if (ret.msg) {
                    this.$y_msg(ret.msg)
                }
            },
            // 授权
            async auth(types) {
                let _this = this;
                let param = {
                    code: this.authCode,
                }
                if (types) {
                    param.bindid = this.bindUser.id;
                }
                let ret = await this.$y_ajax('api/login/authorize', param)
                if (ret.code == 200) {
                    this.$y_setWebToken(ret.data.token);
                    let res = await this.$y_getUser();
                    if (!res.phone) {
                        this.authCode = '';
                        this.showAuthPhone = 1;
                    } else {
                        setTimeout(() => {
                            _this._h5home();
                        }, 1500)
                    }
                } else if (ret.msg) {
                    this.$y_msg(ret.msg)
                    this.authCode = '';
                    this.login();
                }
            },
            // 授权手机号
            async authPhone() {
                let {
                    code,
                    msg
                } = await this.$y_ajax('api/login/bind_auth_phone', {
                    token: this.tzm,
                    channel: 1
                })
                let _this = this;
                this.$y_msg(msg)
                if (code == 200) {
                    setTimeout(() => {
                        _this._h5home();
                    }, 1500)
                }
            },
            // 绑定手机号
            async bandPhone() {
                let _this = this;
                if (!this.phone) {
                    this.$y_msg("请输入手机号");
                    return;
                }
                if (!this.code) {
                    this.$y_msg("请输入验证码");
                    return;
                }
                let {
                    code,
                    msg
                } = await this.$y_ajax('api/user/editInfo', {
                    user_id: this.$y_getKey('userid'),
                    phone: this.phone,
                    code: this.code,
                    device_id: this.$y_device(this.phone),
                })
                this.$y_msg(msg);
                if (code == 200) {
                    setTimeout(() => {
                        _this._h5home();
                    }, 1500)
                }
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .view {
        text-align: center;
    }

    .logo {
        margin: 50px 0 30px;
        width: 80px;
        border-radius: 10px;
    }

    .title {
        margin-bottom: 20px;
    }

    .des {
        color: #555;
        line-height: 1.5;
    }

    .btn {
        width: 60vw;
        margin-top: 30px;
    }

    .input-box {
        display: flex;
        align-items: center;
        border: 1px solid #eee;
        width: 80vw;
        margin-left: 10vw;
        box-sizing: border-box;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 10px;

        input {
            flex: 1;
            margin-left: 10px;
        }
    }
</style>